<template>
    <header class="header container">
        <div class="brand">
          <img class="logo" src="@/assets/test.png" />
          <!-- <h1 class="container maintext" style="margin-left: 10px; margin-right: 30px;">SkinBunker.com</h1> -->
          <nav>
            <h1 to="/trade" v-if="isLoggedIn" :class="{ active: $route.path === '/trade' }">Instant Trade</h1>
            <h1 to="/sell" v-if="isLoggedIn" :class="{ active: $route.path === '/sell' }">Instant Sell</h1>
            <h1 to="/p2p" v-if="isLoggedIn" :class="{ active: $route.path === '/p2p' }">P2P</h1>
          </nav>
        </div>
          
      
      <nav>
        <h1 to="/login?intent=login" v-if="!isLoggedIn">Login</h1>
        <h1 to="/login?intent=register" v-if="!isLoggedIn">Register</h1>
        <div v-if="isLoggedIn" class="balance-container">
          <h1 class="balance-display">Balance: ${{ UserStore.balance }}</h1>
        </div>
        <div 
          v-if="isLoggedIn" 
          class="container"
          @click="isMenuOpen = !isMenuOpen"
        >
          <img :src="UserStore.avatar_url" style="width: 40px; height: 40px; border-radius: 20px;">
          <h1>{{ UserStore.username }}</h1>
          <ul v-if="isMenuOpen" class="profile-menu">
            <li><h1 to="/settings">Settings</h1></li>
            <li><h1 to="/support">Support</h1></li>
            <li><h1 to="/faq">FAQ</h1></li>
            <li><h1 to="/bots">Bots</h1></li>
          </ul>
        </div>
      </nav>
    </header>
</template>

<style scoped>
/* HEADER */
.header {
  width: 100vw;
  background-color: var(--bg-4);  /* Slightly darker for header distinction */
  color: var(--text-color);
  box-shadow: var(--panel-shadow); 
  text-align: center;  /* Center the logo and navigation links */
  max-width: none;
}

.header img {
  margin: auto 0; /* Space below the logo */
}

.header nav {
  white-space: nowrap; 
  display: flex; /* Arrange links horizontally */
  justify-content: center; /* Distribute links evenly */
}

.header nav h1 {
  color: var(--text-color);
  text-decoration: none; /* Remove link underlines */
  margin-left: 5px;
  margin-right: 5px;
  transition: color 0.3s ease; /* Smooth color change on hover */
}

.header nav h1::after {
   content: "|";
   margin-left: 10px;
   color: var(--maintext-color); /* Subtle divider color */
}

.header nav h1:last-child::after {
  content: none; /* Or use display: none; to hide it completely*/
}

.header nav h1.active { 
  text-underline-offset: 3px;
  text-decoration: underline solid var(--subtext-color);
  /* border-bottom: 2px solid rgba(255, 255, 255, 0.2);  */
}

.header nav h1:hover {
  color: var(--subtext-color); /* Subtle color change on hover */
}

/* profile */
.header nav .container { /* Assuming .container is for the profile section */
  position: relative;
  background-color: var(--bg-2); /* Slightly lighter than the header background */
  border-radius: 16px; 
  padding: 4px;
  box-shadow: var(--panel-shadow); /* More prominent shadow */
}

.header nav .container:hover {
    background-color: var(--bg-1); /* Slightly lighter on hover */
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.6); /* More prominent shadow */
}

.header nav .container h1 {
  margin: 12px
}

/* .header nav .container img {
  margin: auto 0;
} */

.profile-menu {
  position: absolute; 
  z-index: 3;
  top: calc(100% + 8px); /* Place just below the container */
  left: 0; /* Align to the left edge of the container */
  background-color: var(--bg-1);  
  border-radius: 8px;
  box-shadow: var(--panel-shadow); 
  padding: 10px;
}

.profile-menu li {
  list-style: none;
  margin-bottom: 5px; 
}

.profile-menu li a {
  color: var(--text-color);
  text-decoration: none; 
  display: block; /* Make links fill the entire list item */
  padding: 5px; 
}

.profile-menu li a:hover {
  background-color: var(--bg-2);
} 

/* brand */
.brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.balance-container {
  width: 100%;
  max-width: none; /* Important: Use none to override */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--bg-2);
  box-shadow: var(--panel-shadow);
  border-radius: 6px;
  margin-right: 20px;
}

.balance-display { 
  background-color: var(--bg-2);
  padding: 5px 10px; 
  border-radius: 5px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}
.logo {
  width: auto;
  height: auto;
  padding: 10px;
}
</style>