<template>
<footer class="footer container">
    <img alt="Vue logo" class="logo" src="@/assets/logo.svg" width="25" height="25" />
    <nav>
      <h1><a href="https://discord.gg/hjZ8xnBCDv">Discord</a></h1>
      <h1 to="/">Contact</h1>
      <h1 to="/">FAQ</h1>
    </nav>
  </footer>
</template>

<style scoped>
/* FOOTER */
.footer {
  background-color: var(--bg-4); /* Even darker for the footer */
  color: var(--text-color);
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.4); /* Inset shadow for top edge */
  padding: 15px; 
  text-align: center; 
  margin-top: auto;
  max-width: none;
}

/* Similar styling for footer nav links, just like in the header */
.footer nav { 
  display: flex; 
  justify-content: center; 
}

.footer nav h1 {
  color: var(--text-color);
  text-decoration: none; 
  margin: 0 10px; 
  transition: color 0.3s ease; 
}

.footer nav h1:hover {
  color: var(--subtext-color); 
}
</style>