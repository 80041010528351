<script setup>

</script>

<template>
  <div class="homepage">
    <img class="backgorund" src="@/assets/mirage.jpg" alt="Background Image">
    <div class="wrapper">
      <h5 class="landing">Coming Soon :)</h5>
      <h4 class="subtext">In the meantime, join our Discord</h4>
      <p class="subtext"><a href="https://discord.gg/tw6yvvJG2k">https://discord.gg/tw6yvvJG2k</a></p>
    </div>
  </div> 
</template>


<style scoped>
body { /* Or your main wrapper element */
  background-color: var(--bg-1); /* Your default dark background */
}

.homepage { 
  height: calc(100vh - 70px - 55px);  /* Replace placeholders */
  position: relative;    
  overflow: hidden; 
}

.backgorund {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;     /* Make image cover full width */
  height: 100%;    /* Make image cover full height */
  object-fit: cover;  /* Fill container while maintaining aspect ratio */
  opacity: 0.2;
  mix-blend-mode: multiply; 
  z-index: -1;
}

.landing {
  color: var(--text-color);
  font-size: 2em;
}
.subtext {
  color: var(--text-color);
  font-size: 0.8em;
}
.wrapper {
  padding-top: 15%;
  text-align: center;
  z-index: 2;
}
.subtext a {
  text-decoration: underline;
}
</style>